/**!
 * EPGList V0.6.1.20181026
 */
(function () {
	const EVENT_NAMES = {
		ITEM_CLICK: 'itemClick',
		LIST_READY: 'listReady',
		LIST_UPDATED: 'listUpdated',
		LIST_LIVE_PROGRAM_UPDATE: 'listLiveProgramUpdate',
		PLAYER_LIVE_PROGRAM_UPDATE: 'playerLiveProgramUpdate',
		VIEWED_ITEMS_CHANGE: 'viewedItemsChange'
	};
	var EPGListFeedCallback = "handleEPGCallback",
			EPGList = function (element, options) {
				this.element = getElement(element);

				this.options = deepExtend({}, EPGList.DEFAULTS, options);
				this.config = deepExtend({}, EPGList.config);
				this.event = new Event();

				this._initEPGConfig();
				this._initEPGFrame();
				this._initClassesEvent();
				this._initEventListener();

				var that = this;
				this._updateEPGList(this.config.epgConfig.epgListDate, function () {
					// EPGList ready event;
					that.options.callback.epgListReady && that.options.callback.epgListReady();
					that.event.trigger(EVENT_NAMES.LIST_READY);

					// Init play live epg
					that.goLive();
				});
			};

	EPGList.DEFAULTS = {
		classes: {
			listWrapper: 'epg-list',
			listItem: 'epg-item',
			state: {
				itemUpcoming: 'is-upcoming',
				itemLive: 'is-live',
				itemPlaying: 'is-playing',
				itemArchive: 'is-archive',
				itemNotAvailable: 'is-not-available', // for hours' limit
				itemLiveNext: 'is-live-next',
				itemPlayingNext: 'is-playing-next',
				selectedDay: 'selected-day'
			},
			event: {
				prevDay: 'prev-day',
				nextDay: 'next-day',
				currentDay: 'current-day',
				thatDay: 'that-day',  // data-date="yyyy/MM/dd"
				goLive: 'go-live'
			},
			disabledEvent: {
				disabledDay: 'disable-day'
			}
		},
		template: {
			epgFrameTemplate: '', // *
			epgFrameData: null, // *
			epgItemTemplate: '', // *
			epgItemData: null, // *
			epgLoadingTemplate: '',
			epgLoadErrorTemplate: ''
		},
		server: {
			locEPGPrefix: '', // *
			serverDate: null,  // * new Date()
			clientOffsetTime: null //*  clientTime - serverTime
		},
		player: {
			isReady: null, // * window.nlReady
			initPlayLive: false  // *
		},
		epgConfig: {
			daysInterval: null,  // Deprecated, please use dateRange
			dateRange: [-1, 1], //
			dvrHours: null, // eg: 72,
			refreshStateIntervalDelay: 60000, // 60 * 1000
			getEPGTimeoutDelay: 5000, // 5 * 1000
			isScroll: false,
			scrollToTopIndex: 0, // Default 0, scroll to top
			isCarousel: false,
			carouselConfig: {},
			clickableItemSubClasses: []  // Default [], full item clickable
		},
		callback: {
			playProgram: null, // * playProgram(epgItem, isLive)
			updateProgram: null, // updateProgram(epgItem), for liveCompleteCallback update page program
			dateChanged: null, // * dateChanged(epgDate, dateIndex)  epgDate format: yyyy/MM/dd
			liveChanged: null, // for not have live complete callback
			getDataWithDate: null, // for custom get epg feed getDataWithDate(epgDate, callback), callback(epgDataArray)
			epgScrolledInView: null, // epgScrolledInView(epgItems), for carousel
			epgListReady: null // epg list ready event
		}
	};

	EPGList.config = {
		player: {
			playingEPGData: null,
			playingEPGDate: null,
			playingEPGIndex: null,
			playingNextEPGDate: null,
			playingNextEPGIndex: null
		},
		eventTypes: {
			playerReadyEvent: 'playerReadyEvent',
			epgCallbackEvent: 'epgCallbackEvent'
		},
		epgConfig: {
			initLiveChanged: false,
			epgLiveDate: null,
			epgLiveIndex: null,
			epgLiveNextDate: null,
			epgLiveNextIndex: null,
			isPlayingLive: false,
			epgListElement: null,
			epgListDate: '',
			gettingEPGDate: '',
			$epgCarousel: null,
			epgCarouselPages: [0, 0],
			isCarouselLoaded: [true, true],
			epgDateArray: [],
			epgDateListData: {},
			refreshStateIntervalID: null,
			refreshAvailableIntervalIDs: {}
		},
		loadStatus: {
			LOADING: 'loading',
			LOADERROR: 'loadError'
		}
	};

	// Public API
	// Support dayOffset and epgDate
	EPGList.prototype.goToDay = function (epgDate) {
		if (indexOfArray(this.config.epgConfig.epgDateArray, epgDate) !== -1)
		{
			this._updateEPGList(epgDate);
		}
	};

	EPGList.prototype.goToDayWithOffset = function (offset) {
		var epgDateArray = this.config.epgConfig.epgDateArray,
			epgListDateIndex = indexOfArray(epgDateArray, this.config.epgConfig.epgLiveDate),
			offsetEpgDate =  epgDateArray[epgListDateIndex + offset];
		this.goToDay(offsetEpgDate);
	};

	EPGList.prototype.getCurrentDayOffset = function () {
		var epgDateArray = this.config.epgConfig.epgDateArray,
				liveDateIndex = indexOfArray(epgDateArray, this.config.epgConfig.epgLiveDate),
			currentListDateIndex = indexOfArray(epgDateArray, this.config.epgConfig.epgListDate);
		return (currentListDateIndex - liveDateIndex);
	};

	EPGList.prototype.goLive = function () {
		let dateRange = this.options.epgConfig.daysInterval ? this.options.epgConfig.daysInterval : this.options.epgConfig.dateRange;
		var that = this,
			epgListDateIndex = indexOfArray(this.config.epgConfig.epgDateArray, this.config.epgConfig.epgListDate),
			beginDayOffset = dateRange[0];
		if (epgListDateIndex + beginDayOffset !== 0)
		{
			var offsetEpgDate = this.config.epgConfig.epgDateArray[(-1) * beginDayOffset];
			this._updateEPGList(offsetEpgDate, playLiveCallback);
		}
		else
		{
			playLiveCallback();
		}

		function playLiveCallback()
		{
			that.scrollToItem(false);

			if (that.options.player.initPlayLive)
			{
				that.playProgramWithState(true, false);
			}
			that.options.player.initPlayLive = true;
		}
	};

	EPGList.prototype.getEPGData = function (epgDate) {
		return this.config.epgConfig.epgDateListData[epgDate];
	};

	EPGList.prototype.getNextEPGItem = function () {
		var currentEPGDate = this.config.player.playingEPGDate,
				currentEPGData = this.config.epgConfig.epgDateListData[currentEPGDate],
				nextEPGItemIndex = typeof this.config.player.playingEPGIndex === 'number' && this.config.player.playingEPGIndex >= 0 ? this.config.player.playingEPGIndex + 1 : null;
		if (currentEPGData && nextEPGItemIndex === currentEPGData.length)
		{
			var epgDateArray = this.config.epgConfig.epgDateArray,
					epgDateIndex = indexOfArray(epgDateArray, currentEPGDate);
			if (epgDateIndex + 1 === epgDateArray.length)
			{
				return null;
			}
			currentEPGData = this.config.epgConfig.epgDateListData[epgDateArray[epgDateIndex + 1]];
			nextEPGItemIndex = 0;
		}
		return currentEPGData && (nextEPGItemIndex !== null && nextEPGItemIndex >= 0) ? currentEPGData[nextEPGItemIndex] : null;
	};

	// Support string formatDate
	EPGList.prototype.getEPGList = function (epgDate, callback) {
		var epgDateData = this.getEPGData(epgDate);
		if (epgDateData)
		{
			callback && callback(epgDateData);
		}
		else
		{
			var that = this;
			this._getEPGFeed(epgDate, function (isSuccess) {
				if (isSuccess)
				{
					callback && callback(that.getEPGData(epgDate));
				}
				else
				{
					callback && callback();

					// Update carousel loading status
					if (that.options.epgConfig.isCarousel)
					{
						if (that.options.epgConfig.gettingEPGDate < that.config.epgConfig.epgLiveDate)
						{
							that.config.epgConfig.isCarouselLoaded[0] = true;
						}
						else if (that.options.epgConfig.gettingEPGDate > that.config.epgConfig.epgLiveDate)
						{
							that.config.epgConfig.isCarouselLoaded[1] = true;
						}
					}
				}
			});
		}
	};

	EPGList.prototype.playProgramWithState = function (isLive, isNext) {
		var epgDate = isLive ? this.config.epgConfig.epgLiveDate : (this.config.player.playingEPGDate || this.config.epgConfig.epgListDate),
				epgData = this.config.epgConfig.epgDateListData[epgDate],
				epgIndex = this.config.player.playingEPGIndex,
				epgLiveElement = this.config.epgConfig.epgListElement.querySelector('.' + this.options.classes.state.itemLive),
				epgLiveIndex = epgLiveElement ? (epgLiveElement.getAttribute('data-index') / 1) : -1,
				hasResetLive = false;
		if (epgLiveIndex >= 0 && (epgIndex === null || isLive))
		{
			epgIndex = epgLiveIndex;
			hasResetLive = true;
		}
		// Play default Program if no epgItem is found in list
		if (epgIndex === null || epgIndex === -1)
		{
			this._playProgramWhenPlayerReady(epgDate, epgIndex, isLive);
		}
		else
		{
			if (!hasResetLive)
			{
				epgIndex += (isNext ? 1 : 0);
			}

			// Process last item before go to next day
			if (epgData && epgIndex === epgData.length)
			{
				var epgDateArray = this.config.epgConfig.epgDateArray,
						epgDateIndex = indexOfArray(epgDateArray, epgDate);
				if (isLive)
				{
					this.config.epgConfig.epgDateArray = this.config.epgConfig.epgDateArray.slice(1);
					this.config.epgConfig.epgLiveDate = epgDateArray[epgDateIndex + 1];
				}
				epgDate = epgDateArray[epgDateIndex + 1];
				epgData = this.config.epgConfig.epgDateListData[epgDate];

				this._updateEPGList(epgDate);

				epgLiveElement = this.config.epgConfig.epgListElement.querySelector('.' + this.options.classes.state.itemLive);
				epgLiveIndex = epgLiveElement ? (epgLiveElement.getAttribute('data-index') / 1) : -1;
				if (isLive && epgLiveIndex !== -1)
				{
					epgIndex = epgLiveIndex;
				}
				else
				{
					epgIndex = 0;
				}
			}

			// Update config.player state
			this._updatePlayerState(epgDate, epgIndex);

			if (isLive && isNext)
			{
				var liveEPG = epgData[epgIndex];
				liveEPG && this.options.callback.updateProgram && this.options.callback.updateProgram(JSON.parse(JSON.stringify(liveEPG)));
				this.event.trigger(EVENT_NAMES.PLAYER_LIVE_PROGRAM_UPDATE, {...liveEPG});
			}
			else
			{
				// Adjust isLive for continue's playing is Live
				if (!isLive && epgIndex === epgLiveIndex && epgLiveIndex !== -1)
				{
					isLive = true;
				}

				this._playProgramWhenPlayerReady(epgDate, epgIndex, isLive);
			}

			// Update playing and next class
			if (epgIndex >= 0)
			{
				var playingElement = this.config.epgConfig.epgListElement.querySelectorAll('[data-date="' + epgDate + '"]')[epgIndex];
				playingElement && this._updatePlayingAndNextClass(playingElement);
			}

			this._processLastEPGItem(epgDate, epgIndex, isLive);
		}
	};

	EPGList.prototype.scrollToItem = function (isPlayingOrLive) {
		if (this.options.epgConfig.isScroll)
		{
			var epgListElement = this.config.epgConfig.epgListElement,
					itemClass = isPlayingOrLive ? this.options.classes.state.itemPlaying : this.options.classes.state.itemLive;
			if (!this.options.epgConfig.isCarousel)
			{
				var itemPlayingElement = epgListElement.querySelector('.' + itemClass);
				if (itemPlayingElement)
				{
					epgListElement && scrollListToIndex(epgListElement, itemPlayingElement.getAttribute('data-index') / 1, this.options.epgConfig.scrollToTopIndex);
				}
			}
			else
			{
				var $itemPlayingElement = $(this.config.epgConfig.epgListElement).find('.' + itemClass).parents('.owl-item');
				$itemPlayingElement.length && this.config.epgConfig.$epgCarousel.trigger('to.owl.carousel', [$itemPlayingElement.index(), 200, true]);
			}
		}
	};

	// Private API
	EPGList.prototype._initEPGConfig = function () {
		let dateRange = this.options.epgConfig.daysInterval ? this.options.epgConfig.daysInterval : this.options.epgConfig.dateRange;
		for (var i = dateRange[0], j = 0; i <= dateRange[1]; i++, j++)
		{
			this.config.epgConfig.epgDateArray[j] = addDaysToDate(this.options.server.serverDate, i);
			// init epgListDate with current date format
			if (i === 0)
			{
				this.config.player.playingEPGDate = this.config.epgConfig.epgListDate = this.config.epgConfig.epgDateArray[j];
			}
		}
		this.config.epgConfig.epgLiveDate = getFormatDate(formatDateToTime(this.options.server.serverDate));
	};

	EPGList.prototype._initEPGFrame = function () {
		var template = this.options.template;
		this.element.innerHTML = initTemplate(template.epgFrameTemplate, template.epgFrameData);
		this.config.epgConfig.epgListElement = this.element.querySelector('.' + this.options.classes.listWrapper);
	};

	EPGList.prototype._getEPGFeed = function (epgDate, callback) {
		var epgUrl = this.options.server.locEPGPrefix + epgDate + '.js?callback=' + EPGListFeedCallback,
				isFeedTimeout = true,
				that = this;

		// Local store requesting epgDate
		this.options.epgConfig.gettingEPGDate = epgDate;

		if (this.options.callback.getDataWithDate)
		{
			this.options.callback.getDataWithDate(epgDate, function (callbackData)
			{
				window[EPGListFeedCallback](callbackData);
				getEPGDateCallback(true);
			});
		}
		else
		{
			getScript(epgUrl, getEPGDateCallback);
		}

		if (epgDate === this.config.epgConfig.epgListDate)
		{
			window.setTimeout(function () {
				if (epgDate === that.config.epgConfig.epgListDate && isFeedTimeout)
				{
					getEPGTimeoutCallback();
				}
			}, this.options.epgConfig.getEPGTimeoutDelay);
		}

		function getEPGDateCallback(isSuccess)
		{
			isFeedTimeout = false;
			callback && callback(isSuccess);

			// Reset Local store requesting epgDate
			that.options.epgConfig.gettingEPGDate = '';
		}

		function getEPGTimeoutCallback()
		{
			callback && callback();
		}
	};

	EPGList.prototype._updateEPGList = function (epgDate, callback) {
		var that = this;
		that.config.epgConfig.epgListDate = epgDate;

		var epgDateIndex = indexOfArray(that.config.epgConfig.epgDateArray, epgDate);
		that.options.callback.dateChanged && that.options.callback.dateChanged(epgDate, epgDateIndex);

		that._updateEventClassState(epgDate);

		that._loadStatus(that.config.loadStatus.LOADING);
		that.getEPGList(epgDate, getEPGListCallback);

		function getEPGListCallback(epgData)
		{
			if (!epgData || (epgData && epgData.length === 0))
			{
				callback && callback();
				that._loadStatus(that.config.loadStatus.LOADERROR);
			}
			else
			{
				var epgDataDate = getFormatDate(epgData[0].sl);

				// Fix: Add case for carousel when init with prev/next date epg feed
				if (that.config.epgConfig.epgListDate === epgDataDate ||
						(that.options.epgConfig.isCarousel && (!that.config.epgConfig.isCarouselLoaded[0] || !that.config.epgConfig.isCarouselLoaded[1]))
				)
				{
					// unbind click event
					that._bindEPGClickEvent(false);

					that._renderEPGList(epgData);

					that.event.trigger(EVENT_NAMES.LIST_UPDATED, {
						epgDate: epgDataDate,
						epgDateIndex: indexOfArray(that.config.epgConfig.epgDateArray, epgDataDate)
					});

					// bind click event
					that._bindEPGClickEvent(true);

					// Update list item state: upcoming, live, archive
					that._refreshListItemState(epgDataDate);

					// Scroll for Non-carousel
					if (!that.options.epgConfig.isCarousel)
					{
						if (epgDataDate === that.config.player.playingEPGDate)
						{
							if (that.config.player.playingEPGIndex !== null)
							{
								// Scroll to playing epg item
								scrollListToIndex(that.config.epgConfig.epgListElement, that.config.player.playingEPGIndex, that.options.epgConfig.scrollToTopIndex);
								// Update playing&next class
								that._updatePlayingAndNextClass(that.config.epgConfig.epgListElement.querySelector('[data-index="' + that.config.player.playingEPGIndex + '"]'));
							}
						}
						else
						{
							// Default scroll to top when current date is not playing in player
							scrollListToIndex(that.config.epgConfig.epgListElement, 0, that.options.epgConfig.scrollToTopIndex);
						}
					}

					callback && callback();
				}
			}
		}
	};

	EPGList.prototype._renderEPGList = function (epgItems) {
		var listDomArray = [],
				epgItemTemplate = this.options.template.epgItemTemplate,
				elementWrapper = document.createElement('div'),
				epgDataDate = getFormatDate(epgItems[0].sl),
				that = this;
		for (var i = 0; i < epgItems.length; i++)
		{
			var epgItemData = this.options.template.epgItemData(epgItems[i]);
			elementWrapper.innerHTML = initTemplate(epgItemTemplate, epgItemData, epgItems[i]);

			var itemElement = this._processItemAttribute(elementWrapper.firstChild, epgItems[i], epgDataDate, i);
			listDomArray[i] = itemElement.outerHTML;
		}
		if (!this.options.epgConfig.isCarousel)
		{
			this.config.epgConfig.epgListElement.innerHTML = listDomArray.join('');
		}
		else
		{
			renderCarouselList(epgDataDate, listDomArray);
		}

		function renderCarouselList(epgDataDate, listDomArray)
		{
			if (epgDataDate === that.config.epgConfig.epgLiveDate)
			{
				if (!that.config.epgConfig.$epgCarousel)
				{
					var initRefresh = false;
					that.config.epgConfig.epgListElement.innerHTML = listDomArray.join('');
					that.config.epgConfig.$epgCarousel = $(that.config.epgConfig.epgListElement)
							.addClass('owl-carousel')
							.on('refreshed.owl.carousel', function (event) {
										if (initRefresh)
										{
											initRefresh = false;
											epgScrolled(event);
										}
									}
							)
							.on('translated.owl.carousel', epgScrolled)
							.owlCarousel(that.options.epgConfig.carouselConfig);

					// Process Live is in first/last page, then get the prev/next epg list;
					setTimeout(function () {
						initRefresh = true;
						that.config.epgConfig.$epgCarousel.trigger('refresh.owl.carousel');
					}, 500);

					function epgScrolled(event)
					{
						activeEPGList();

						// Auto get epg list
						autoGetEPGList(event);
					}

					function activeEPGList()
					{
						var activeOwlItems = that.config.epgConfig.epgListElement.querySelectorAll('.owl-item.active'),
								activeEPGItems = [];

						for (var i = 0, l = activeOwlItems.length; i < l; i++)
						{
							var activeEPGItem = activeOwlItems[i].children[0],
									date = activeEPGItem.getAttribute('data-date'),
									index = parseInt(activeEPGItem.getAttribute('data-index'), 10);
							activeEPGItems[i] = that.config.epgConfig.epgDateListData[date][index];
						}
						// Trigger epgScrolledInView, support callback and trigger event
						that.options.callback.epgScrolledInView && that.options.callback.epgScrolledInView(activeEPGItems);
						that.event.trigger(EVENT_NAMES.VIEWED_ITEMS_CHANGE, activeEPGItems);
					}

					function autoGetEPGList(event)
					{
						var pages = event.page.count,
								page = event.page.index;
						if (page === 0 && that.config.epgConfig.isCarouselLoaded[0])
						{
							that._getCarouselEPGList(false);
						}
						if ((page === pages - 1 || (page === pages - 2 && pages + event.item.index >= event.item.count)) && that.config.epgConfig.isCarouselLoaded[1])
						{
							that._getCarouselEPGList(true);
						}
					}
				}
			}
			else if (epgDataDate < that.config.epgConfig.epgLiveDate)
			{
				var $firstActiveItem = $($(that.config.epgConfig.epgListElement).find('.active')[0]);
				for (var i = listDomArray.length - 1; i >= 0; i--)
				{
					that.config.epgConfig.$epgCarousel.trigger('add.owl.carousel', [listDomArray[i], 0]);
				}

				that.config.epgConfig.$epgCarousel.trigger('refresh.owl.carousel')
						.trigger('to.owl.carousel', [$firstActiveItem.index(), 0, true]);
				that.config.epgConfig.isCarouselLoaded[0] = true;
			}
			else
			{
				listDomArray.forEach(function (currentValue) {
					that.config.epgConfig.$epgCarousel.trigger('add.owl.carousel', [currentValue]);
				});
				that.config.epgConfig.$epgCarousel.trigger('refresh.owl.carousel');
				that.config.epgConfig.isCarouselLoaded[1] = true;
			}
		}
	};

	EPGList.prototype._getCarouselEPGList = function (isNext) {
		var index = isNext ? 1 : 0,
				delta = isNext ? 1 : -1;
		this.config.epgConfig.isCarouselLoaded[index] = !this.config.epgConfig.isCarouselLoaded[index];
		this.config.epgConfig.epgCarouselPages[index] = this.config.epgConfig.epgCarouselPages[index] + delta;

		// Check carousel page index is available in dateRange.
		let dateRange = this.options.epgConfig.daysInterval ? this.options.epgConfig.daysInterval : this.options.epgConfig.dateRange;
		if ((isNext && this.config.epgConfig.epgCarouselPages[index] <= dateRange[index]) ||
				(!isNext && this.config.epgConfig.epgCarouselPages[index] >= dateRange[index]))
		{
			this.goToDayWithOffset(this.config.epgConfig.epgCarouselPages[index]);
		}
	};

	EPGList.prototype._bindEPGClickEvent = function (isBind) {
		var epgListItems = this.config.epgConfig.epgListElement.querySelectorAll('.' + this.options.classes.listItem),
			epgListLength = epgListItems.length,
			that = this;
		if (epgListLength > 0)
		{
			var clickableItemClasses = that.options.epgConfig.clickableItemSubClasses;
			if (clickableItemClasses && clickableItemClasses.length > 0)
			{
				clickableItemClasses.forEach(function (clickableClass) {
					[].forEach.call(epgListItems, function (element) {
						bindClick(element.querySelectorAll('.' + clickableClass), isBind);
					});
				})
			}
			else
			{
				// Default epg item element
				bindClick(epgListItems, isBind);
			}
		}

		function bindClick(elements, isBind)
		{
			if (!that.options.epgConfig.isCarousel)
			{
				var eventListenerName = isBind !== false ? 'on' : 'off';
				[].forEach.call(elements, function (element) {
					extendOnOff(element)[eventListenerName]('click', epgListClicked);
				});
			}
			else
			{
				bindClickIgnoreDrag(elements, epgListClicked, isBind);
			}
		}

		function epgListClicked(event)
		{
			var itemElement = closet(event.target, that.options.classes.listItem),
					stateClasses = that.options.classes.state,
					epgDate = itemElement.getAttribute('data-date'),
					epgIndex = itemElement.getAttribute('data-index') / 1,
					isLive = hasClass(itemElement, stateClasses.itemLive),
					isAvailable = false;
			if (!hasClass(itemElement, stateClasses.itemNotAvailable) && !hasClass(itemElement, stateClasses.itemUpcoming))
			{
				isAvailable = true;
				if (!that.options.epgConfig.isCarousel && that.options.epgConfig.isScroll)
				{
					scrollListToIndex(that.config.epgConfig.epgListElement, epgIndex, that.options.epgConfig.scrollToTopIndex);
				}

				that._playProgramWhenPlayerReady(epgDate, epgIndex, isLive);

				that._processLastEPGItem(epgDate, epgIndex, isLive);
			}

			that.event.trigger(EVENT_NAMES.ITEM_CLICK, {
				itemData: {...that.config.epgConfig.epgDateListData[epgDate][epgIndex]},
				isLive: isLive,
				isAvailable: isAvailable
			});
		}
	};

	EPGList.prototype._initClassesEvent = function () {
		var that = this;
		that.element.addEventListener('click', bindClassesEvent);

		function bindClassesEvent(event)
		{
			var eventClasses = that.options.classes.event,
					closetClass = '',
					closetElement = null;
			for (var classKey in eventClasses)
			{
				if (eventClasses.hasOwnProperty(classKey))
				{
					closetElement = closet(event.target, eventClasses[classKey]);
					if (closetElement)
					{
						closetClass = classKey;
						break;
					}
				}
			}
			switch (closetClass)
			{
				case 'prevDay':
					that.goToDayWithOffset(that.getCurrentDayOffset() - 1);
					break;
				case 'nextDay':
					that.goToDayWithOffset(that.getCurrentDayOffset() + 1);
					break;
				case 'thatDay':
					var thatDayDate = closetElement.getAttribute('data-date');
					thatDayDate !== null && that.goToDay(thatDayDate);
					break;
				case 'currentDay':
					that.goToDay(that.config.epgConfig.epgLiveDate);
					break;
				case 'goLive':
					that.goLive();
					break;
				default:
					break;
			}
		}
	};

	EPGList.prototype._initEventListener = function () {
		var that = this;

		// Event: EPG callback
		document.addEventListener(that.config.eventTypes.epgCallbackEvent, epgCallbackEvent);

		function epgCallbackEvent(epgDataDetail)
		{
			let epgSourceData = epgDataDetail.detail;
			if (!that.options.callback.getDataWithDate && epgSourceData.length && !!epgSourceData[0].items)
			{
				epgSourceData = epgSourceData[0].items;
			}
			if (epgSourceData.length === 0)
			{
				that.config.epgConfig.epgDateListData[that.options.epgConfig.gettingEPGDate] = [];
			}
			else
			{
				if (epgSourceData.length > 0)
				{
					that.config.epgConfig.epgDateListData[getFormatDate(epgSourceData[0].sl)] = processEPGData(epgSourceData);
				}
				else
				{
					if (that.options.epgConfig.gettingEPGDate !== '')
					{
						that.config.epgConfig.epgDateListData[that.options.epgConfig.gettingEPGDate] = processEPGData(epgSourceData);
					}
				}
			}
		}

		function processEPGData(epgItems)
		{
			// For format date string
			var dateRegExp = /([0-9]{4})\-([0-9]{2})\-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2})(?:[\0-\t\x0B\f\x0E-\u2027\u202A-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])([0-9]{3})/;

			for (var i = 0, l = epgItems.length; i < l; i++)
			{
				epgItems[i].index = i;
				epgItems[i].eu = addSecondsToTime(epgItems[i].su + 'Z', epgItems[i].d * 60);
				epgItems[i].el = addSecondsToTime(epgItems[i].sl + 'Z', epgItems[i].d * 60);

				// Below properties are for player
				epgItems[i].date = epgItems[i].sl;
				epgItems[i].beginDateTimeGMT = epgItems[i].su;
				epgItems[i].nextDateTime = epgItems[i].el;
				epgItems[i].nextDateTimeGMT = epgItems[i].eu;
				epgItems[i].epgShowName = epgItems[i].e;
				epgItems[i].epgShowTime = epgItems[i].sl.replace(dateRegExp, '$1-$2-$3 $4:$5');

				// Process undefined properties with default empty string
				epgItems[i].ed = epgItems[i].ed === undefined ? '' : epgItems[i].ed;
				epgItems[i].t = epgItems[i].t === undefined ? '' : epgItems[i].t;
			}
			return epgItems;
		}
	};

	EPGList.prototype._processItemAttribute = function (itemElement, epgData, epgDate, index) {
		var startTime = new Date(epgData.su + 'Z').getTime(),
				endTime = startTime + epgData.d / 1 * 60 * 1000;
		itemElement.setAttribute('data-index', index);
		itemElement.setAttribute('data-date', epgDate);
		itemElement.setAttribute('startTime', startTime);
		itemElement.setAttribute('endTime', endTime);
		return itemElement;
	};

	EPGList.prototype._refreshListItemState = function (epgDataDate) {
		var that = this;
		if (that.config.epgConfig.refreshStateIntervalID)
		{
			window.clearInterval(that.config.epgConfig.refreshStateIntervalID);
			that.config.epgConfig.refreshStateIntervalID = null;
		}
		updateListItemState(epgDataDate);

		var timeoutSeconds = (60 - (new Date(new Date().getTime() - that.options.server.clientOffsetTime)).getSeconds() + 1) * 1000;
		// Set interval update for live date
		if (that.config.epgConfig.epgListDate === that.config.epgConfig.epgLiveDate)
		{
			window.setTimeout(function () {
				updateListItemState();
				that.config.epgConfig.refreshStateIntervalID = window.setInterval(updateListItemState, that.options.epgConfig.refreshStateIntervalDelay);
			}, timeoutSeconds);
		}
		else if (dvrHoursInDate(epgDataDate))
		{
			if (that.config.epgConfig.refreshAvailableIntervalIDs[epgDataDate])
			{
				window.clearInterval(that.config.epgConfig.refreshAvailableIntervalIDs[epgDataDate]);
				that.config.epgConfig.refreshAvailableIntervalIDs[epgDataDate] = null;
			}
			window.setTimeout(function () {
				updateNonDVRItems(epgDataDate);
				that.config.epgConfig.refreshAvailableIntervalIDs[epgDataDate] = window.setInterval(function () {
					updateNonDVRItems(epgDataDate);
				}, that.options.epgConfig.refreshStateIntervalDelay);
			}, timeoutSeconds);
		}

		// List: Update playing state when switch to the playing day
		if (epgDataDate === that.config.player.playingEPGDate && that.config.player.playingEPGIndex !== null && !that.options.epgConfig.isCarousel)
		{
			var itemIndexElement = that.config.epgConfig.epgListElement.querySelector('[data-index="' + that.config.player.playingEPGIndex + '"]');
			if (itemIndexElement)
			{
				that._updatePlayingAndNextClass(itemIndexElement);
				that.options.epgConfig.isScroll && scrollListToIndex(that.config.epgConfig.epgListElement, that.config.player.playingEPGIndex, that.options.epgConfig.scrollToTopIndex);
			}
		}

		// Update 'is-next' class when playing is the last of that day
		if (that.config.epgConfig.epgDateArray.indexOf(epgDataDate) - 1 === that.config.epgConfig.epgDateArray.indexOf(that.config.player.playingEPGDate) &&
		that.config.player.playingEPGIndex === that.config.epgConfig.epgDateListData[that.config.player.playingEPGDate].length - 1)
		{
			if (this.options.epgConfig.isCarousel)
			{
				var playingEPGList = that.config.epgConfig.epgListElement.querySelectorAll('[data-date="' + (that.config.player.playingEPGDate) + '"]'),
						playingElement = playingEPGList[playingEPGList.length - 1];
				!!playingElement && that._updatePlayingAndNextClass(playingElement);
			}
			else
			{
				var epgNextDateList = that.config.epgConfig.epgListElement.querySelectorAll('[data-date="' + epgDataDate + '"]');
				epgNextDateList.length && addPlayingNextClass(epgNextDateList[0]);
			}
		}

		// Set liveNext class for liveNext date when live is the last in live date
		if (that.config.epgConfig.epgDateArray.indexOf(epgDataDate) - 1 === that.config.epgConfig.epgDateArray.indexOf(that.config.epgConfig.epgLiveDate) &&
				that.config.epgConfig.epgLiveIndex === that.config.epgConfig.epgDateListData[that.config.epgConfig.epgLiveDate].length - 1)
		{
			var epgDataDateList = that.config.epgConfig.epgListElement.querySelectorAll('[data-date="' + epgDataDate + '"]');
			epgDataDateList.length && addLiveNextClass(epgDataDateList[0]);
		}

		function updateListItemState(epgDataDate)
		{
			var epgListElementsDate = epgDataDate ? epgDataDate : that.config.epgConfig.epgLiveDate,
					epgListElements = that.config.epgConfig.epgListElement.querySelectorAll('[data-date="' + epgListElementsDate + '"]'),
					stateClasses = that.options.classes.state,
					hasAvailableHours = that.options.epgConfig.dvrHours !== null,
					liveElement = that.config.epgConfig.epgListElement.querySelector('[data-date="' + epgListElementsDate + '"]' + '.' + stateClasses.itemLive),
					liveNextElement = that.config.epgConfig.epgListElement.querySelector('[data-date="' + epgListElementsDate + '"]' + '.' + stateClasses.itemLiveNext),
					liveElementIndex = liveElement ? liveElement.getAttribute('data-index') / 1 : null;

			// Remove live & liveNext class
			liveElement && removeClass(liveElement, stateClasses.itemLive);
			liveNextElement && removeClass(liveNextElement, stateClasses.itemLiveNext);

			for(var i = 0, l = epgListElements.length; i < l ; i++)
			{
				var isUpcomingClass = hasClass(epgListElements[i], stateClasses.itemUpcoming);

				removeClass(epgListElements[i], stateClasses.itemArchive);
				removeClass(epgListElements[i], stateClasses.itemUpcoming);
				removeClass(epgListElements[i], stateClasses.itemNotAvailable);

				var epgStartTime = epgListElements[i].getAttribute('startTime') / 1,
						epgEndTime = epgListElements[i].getAttribute('endTime') / 1,
						stateClass = getStateClass(epgStartTime, epgEndTime);

				// Add item not available class for which has dvrHours setting and in dvrHours and not live item
				if (hasAvailableHours && !isInDVRHours(epgStartTime) && stateClass !== stateClasses.itemLive)
				{
					addClass(epgListElements[i], stateClasses.itemNotAvailable);
				}

				addClass(epgListElements[i], stateClass);

				// For live next class
				if (stateClass === stateClasses.itemLive)
				{
					that._updateLiveState(epgListElementsDate, i);

					if (i < l - 1)
					{
						addLiveNextClass(epgListElements[i + 1]);
					}
					else if (i === l - 1 && that.options.epgConfig.isCarousel)
					{
						var nextSibling = null;
						if (epgListElements[i].parentNode.nextSibling)
						{
							nextSibling = epgListElements[i].parentNode.nextSibling.firstChild;
						}
						!!nextSibling && addLiveNextClass(nextSibling);
					}
				}

				if ((!that.config.epgConfig.initLiveChanged || isUpcomingClass) && stateClass === stateClasses.itemLive)
				{
					that._listLiveChanged(i);
				}
				if (stateClass === stateClasses.itemLive && liveElementIndex !== i)
				{
					let epgConfig = that.config.epgConfig,
							liveEPG = epgConfig.epgDateListData[epgConfig.epgListDate][i];
					that.event.trigger(EVENT_NAMES.LIST_LIVE_PROGRAM_UPDATE, {...liveEPG});
				}

				// For cross day, last live to first live
				if (i === l - 1 && liveElementIndex === i && stateClass === stateClasses.itemArchive && that.options.epgConfig.isCarousel)
				{
					var epgDateIndex = indexOfArray(that.config.epgConfig.epgDateArray, epgListElementsDate),
							nextLiveDate =  that.config.epgConfig.epgDateArray[epgDateIndex + 1];
					that._updateLiveState(nextLiveDate, 0);
					that._refreshListItemState(nextLiveDate);
				}

				// Update playing/live next item class for cross day
				if (i === 0)
				{
					var epgDateArray = that.config.epgConfig.epgDateArray,
							epgDateIndex = indexOfArray(epgDateArray, epgListElementsDate),
							playingDateIndex = indexOfArray(epgDateArray, that.config.player.playingEPGDate),
							liveDateIndex = indexOfArray(epgDateArray, that.config.epgConfig.epgLiveDate);
					if (epgDateIndex === playingDateIndex + 1 && that.config.player.playingEPGIndex === that.getEPGData(that.config.player.playingEPGDate).length - 1)
					{
						addPlayingNextClass(epgListElements[i]);
					}
					if (epgDateIndex === liveDateIndex + 1 && that.config.epgConfig.epgLiveIndex === that.getEPGData(that.config.epgConfig.epgLiveDate).length - 1)
					{
						addLiveNextClass(epgListElements[i]);
					}
				}
			}
		}
		function updateNonDVRItems(epgDate)
		{
			var epgListElements = that.config.epgConfig.epgListElement.querySelectorAll('[data-date="' + epgDate + '"]'),
					itemNotAvailableClasses = that.options.classes.state.itemNotAvailable;

			for (var i = 0, l = epgListElements.length; i < l; i++)
			{
				if (!hasClass(epgListElements[i], itemNotAvailableClasses) &&
						!isInDVRHours(epgListElements[i].getAttribute('startTime') / 1))
				{
					addClass(epgListElements[i], itemNotAvailableClasses);
				}
			}
		}
		function dvrHoursInDate(epgDate)
		{
			if (that.options.epgConfig.dvrHours && (epgDataDate < that.config.epgConfig.epgLiveDate))
			{
				let dateRange = that.options.epgConfig.daysInterval ? that.options.epgConfig.daysInterval : that.options.epgConfig.dateRange;
				var fromDateIndex = Math.ceil(that.options.epgConfig.dvrHours / 24);
				return dateRange[0] + that.config.epgConfig.epgDateArray.indexOf(epgDate) + fromDateIndex >= 0;
			}
			return false;
		}
		function isInDVRHours(time)
		{
			var currentTime = new Date().getTime() - that.options.server.clientOffsetTime,
				dvrBeginTime = currentTime - that.options.epgConfig.dvrHours * 60 * 60 * 1000;
			return time >= dvrBeginTime;
		}
		function getStateClass(startTime, endTime)
		{
			var stateClass = '',
				time = (new Date()).getTime() - that.options.server.clientOffsetTime;
			startTime = startTime / 1;
			endTime = endTime / 1;
			if (time < startTime)
			{
				stateClass = that.options.classes.state.itemUpcoming;
			}
			else if (time >= startTime && time <= endTime)
			{
				stateClass = that.options.classes.state.itemLive;
			}
			else
			{
				stateClass = that.options.classes.state.itemArchive;
			}
			return stateClass;
		}
		function addPlayingNextClass(epgElement)
		{
			addClass(epgElement, that.options.classes.state.itemPlayingNext);
		}
		function addLiveNextClass(epgElement)
		{
			addClass(epgElement, that.options.classes.state.itemLiveNext);
		}
	};

	EPGList.prototype._listLiveChanged = function (index) {
		var epgConfig = this.config.epgConfig;
		if (epgConfig.isPlayingLive || !epgConfig.initLiveChanged)
		{
			this.config.epgConfig.initLiveChanged = true;
			this.options.callback.liveChanged && this.options.callback.liveChanged(epgConfig.epgDateListData[epgConfig.epgListDate][index]);
		}
	};

	EPGList.prototype._processLastEPGItem = function (epgDate, epgIndex, isLive) {
		var isLastEPGItem = this.config.epgConfig.epgDateListData[epgDate].length === epgIndex + 1;
		if(isLastEPGItem)
		{
			var epgDateArray = this.config.epgConfig.epgDateArray,
					epgDateIndex = indexOfArray(epgDateArray, epgDate),
					nextEPGDate = '';
			if (epgDateIndex + 1 === epgDateArray.length)
			{
				let dateRange = this.options.epgConfig.daysInterval ? this.options.epgConfig.daysInterval : this.options.epgConfig.dateRange;
				nextEPGDate = addDaysToDate(this.options.server.serverDate, dateRange[1] + 1);
				this.config.epgConfig.epgDateArray.push(nextEPGDate);
			}
			else
			{
				nextEPGDate = epgDateArray[epgDateIndex + 1];
			}
			if (!this.config.epgConfig.epgDateListData[nextEPGDate])
			{
				this.getEPGList(nextEPGDate);
			}
		}
	};

	EPGList.prototype._playProgramWhenPlayerReady = function (epgDate, epgIndex, isLive) {
		var times = 600, timeout = 100, that = this; // 600times and 0.1s
		checkPlayerIsReady();

		function checkPlayerIsReady()
		{
			if (window[that.options.player.isReady])
			{
				// Update config.player state
				that._updatePlayerState(epgDate, epgIndex);

				that._updatePlayingState(epgDate, epgIndex, isLive);

				// Update state
				that.config.epgConfig.isPlayingLive = !!isLive;

				// Play Program
				var epgItem = (typeof epgIndex === 'number' && epgIndex >= 0) ? that.config.epgConfig.epgDateListData[epgDate][epgIndex] : null;
				that.options.callback.playProgram && that.options.callback.playProgram((epgItem && JSON.parse(JSON.stringify(epgItem))), isLive);
			}
			else
			{
				if (--times)
				{
					window.setTimeout(checkPlayerIsReady, timeout);
				}
			}
		}
	};

	EPGList.prototype._updatePlayerState = function (epgDate, epgIndex) {
		this.config.player.playingEPGData = this.config.epgConfig.epgDateListData[epgDate];
		this.config.player.playingEPGDate = epgDate;
		this.config.player.playingEPGIndex = epgIndex;

		var epgData = this.getEPGData(epgDate);
		if (epgData && epgIndex === epgData.length - 1)
		{
			var epgDateArray = this.config.epgConfig.epgDateArray,
					epgDateIndex = indexOfArray(epgDateArray, epgDate);
			this.config.player.playingNextEPGDate = epgDateArray[epgDateIndex + 1];
			this.config.player.playingNextEPGIndex = 0;
		}
		else
		{
			this.config.player.playingNextEPGDate = epgDate;
			this.config.player.playingNextEPGIndex = epgIndex + 1;
		}
	};

	EPGList.prototype._updateLiveState = function (epgDate, epgIndex) {
		this.config.epgConfig.epgLiveDate = epgDate;
		this.config.epgConfig.epgLiveIndex = epgIndex;

		var epgData = this.getEPGData(epgDate);
		if (epgData && epgIndex === epgData.length - 1)
		{
			var epgDateArray = this.config.epgConfig.epgDateArray,
					epgDateIndex = indexOfArray(epgDateArray, epgDate);
			this.config.epgConfig.epgLiveNextDate = epgDateArray[epgDateIndex + 1];
			this.config.epgConfig.epgLiveNextIndex = 0;
		}
		else
		{
			this.config.epgConfig.epgLiveNextDate = epgDate;
			this.config.epgConfig.epgLiveNextIndex = epgIndex + 1;
		}
	};

	EPGList.prototype._updatePlayingState = function (epgDate, epgIndex, isLive) {
		var epgListElement = this.config.epgConfig.epgListElement;
		if (isLive)
		{
			var liveItem = epgListElement.querySelector('.' + this.options.classes.state.itemLive);
			if (liveItem)
			{
				epgIndex = liveItem.getAttribute('data-index') / 1;
			}
		}

		var itemIndexElements = epgListElement.querySelectorAll('[data-index="' + epgIndex + '"]');
		if (!this.options.epgConfig.isCarousel)
		{
			if (epgDate === this.config.epgConfig.epgListDate)
			{
				itemIndexElements.length && this._updatePlayingAndNextClass(itemIndexElements[0]);
			}
		}
		else
		{
			for (var i = 0, l = itemIndexElements.length; i < l; i++)
			{
				if (itemIndexElements[i].getAttribute('data-date') === epgDate)
				{
					this._updatePlayingAndNextClass(itemIndexElements[i]);
					break;
				}
			}
		}
		if (epgDate === this.config.player.playingEPGDate)
		{
			this.config.player.playingEPGIndex = epgIndex;
		}
	};

	EPGList.prototype._updateEventClassState = function (updateEPGDate) {
		var epgDateArray = this.config.epgConfig.epgDateArray,
				dateIndex = indexOfArray(epgDateArray, updateEPGDate),
				disabledDayClass = this.options.classes.disabledEvent.disabledDay,
				selectedDayClass = this.options.classes.state.selectedDay,
				currentDayClass = this.options.classes.event.currentDay,
				prevDayElement = this.element.querySelector('.' + this.options.classes.event.prevDay),
				nextDayElement = this.element.querySelector('.' + this.options.classes.event.nextDay),
				selectedDayElement = this.element.querySelector('.' + selectedDayClass),
				currentDayElement = this.element.querySelector('.' + currentDayClass);
		if (prevDayElement)
		{
			removeClass(prevDayElement, disabledDayClass);
			dateIndex === 0 && addClass(prevDayElement, disabledDayClass);
		}
		if (nextDayElement)
		{
			removeClass(nextDayElement, disabledDayClass);
			dateIndex === epgDateArray.length - 1 && addClass(nextDayElement, disabledDayClass);
		}
		// Update selectedDay class
		if (!selectedDayElement || (selectedDayElement && selectedDayElement.getAttribute('data-date') !== updateEPGDate))
		{
			selectedDayElement && removeClass(selectedDayElement, selectedDayClass);
			var allThatDaysElements = this.element.querySelectorAll('.' + this.options.classes.event.thatDay);
			for (var i = 0, l = allThatDaysElements.length; i < l; i++)
			{
				if (allThatDaysElements[i].getAttribute('data-date') === updateEPGDate)
				{
					addClass(allThatDaysElements[i], selectedDayClass);
					break;
				}
			}
		}

		// Update currentDay data-date attribute
		if (currentDayElement)
		{
			currentDayElement.setAttribute('data-date', updateEPGDate);
		}
	};

	EPGList.prototype._updatePlayingAndNextClass = function (itemIndexElement) {
		// Remove
		var epgListElement = this.config.epgConfig.epgListElement,
				itemPlayingClass = this.options.classes.state.itemPlaying,
				itemPlayingElement = epgListElement.querySelector('.' + itemPlayingClass),
				itemPlayingNextClass = this.options.classes.state.itemPlayingNext,
				itemPlayingNextElement = epgListElement.querySelector('.' + itemPlayingNextClass);
		itemPlayingElement && removeClass(itemPlayingElement, itemPlayingClass);
		itemPlayingNextElement && removeClass(itemPlayingNextElement, itemPlayingNextClass);

		// Add itemPlaying class
		addClass(itemIndexElement, this.options.classes.state.itemPlaying);

		// Add itemPlayingNext class
		var nextSibling = itemIndexElement && itemIndexElement.nextSibling;
		if (this.options.epgConfig.isCarousel)
		{
			if (itemIndexElement.parentNode.nextSibling)
			{
				nextSibling = itemIndexElement.parentNode.nextSibling.firstChild;
			}
			// Ignore not found case and wait auto get next day's EPG
		}
		if (!!nextSibling)
		{
			addClass(nextSibling, this.options.classes.state.itemPlayingNext);
		}
	};

	EPGList.prototype._loadStatus = function (state)
	{
		if (!this.options.epgConfig.isCarousel)
		{
			var loadStatusTemplate = '';
			if (state === this.config.loadStatus.LOADING)
			{
				loadStatusTemplate = this.options.template.epgLoadingTemplate;
			}
			else if (state === this.config.loadStatus.LOADERROR)
			{
				loadStatusTemplate = this.options.template.epgLoadErrorTemplate;
			}
			this.config.epgConfig.epgListElement.innerHTML = loadStatusTemplate;
		}
	};

	// Utils
	// Convert '2017-04-20T23:00:00.000' to '2017/04/20'
	function getFormatDate(dateString)
	{
		return dateString.split("-")[0] + '/' + dateString.split("-")[1] + '/' + dateString.split("-")[2].substring(0, 2);
	}

	// Result patten: yyyy/MM/dd
	function addDaysToDate(formatDate, days)
	{
		var newDate = new Date(formatDate),
				resultDate = new Date(newDate.getTime() + days * 24 * 3600 * 1000),
				month = resultDate.getMonth() + 1,
				day = resultDate.getDate();
		return resultDate.getFullYear() + '/' + (month > 9 ? month : ('0' + month)) + '/' + (day > 9 ? day : ('0' + day));
	}

	// Source/Result pattern:2017-04-20T23:00:00.000
	function addSecondsToTime(timeStr, seconds)
	{
		var addedDate = new Date((new Date(timeStr)).getTime() + (seconds / 1) * 1000 + new Date().getTimezoneOffset() * 60 * 1000);
		return formatDateToTime(addedDate);
	}

	// convert Date obj to pattern "2012-02-04T11:42:33.718"
	function formatDateToTime(date)
	{
		var y = date.getFullYear();
		var m = date.getMonth() + 1;
		if (m < 10) m = "0" + m;
		var d = date.getDate();
		if (d < 10) d = "0" + d;
		var hour = date.getHours();
		if (hour < 10) hour = "0" + hour;
		var min = date.getMinutes();
		if (min < 10) min = "0" + min;
		var sec = date.getSeconds();
		if (sec < 10) sec = "0" + sec;
		var mill = date.getMilliseconds();
		if (mill < 10) mill = "00" + mill;
		else if (mill < 100) mill = "0" + mill;
		return y + "-" + m + "-" + d + "T" + hour + ":" + min + ":" + sec + "." + mill;
	}

	function scrollListToIndex(listFolder, index, toTopIndex, duration)
	{
		if (index === 0)
		{
			scrollTo(listFolder, 0, duration);
		}
		else
		{
			var listItems = listFolder.childNodes,
				scrollOffset = 0,
				contentHeight = 0,
				scrollToCenter = 0;
			duration = (duration === undefined ? 500 : duration);
			for (var i = 0, l = listItems.length; i < l; i++)
			{
				var listItemHeight = listItems[i].offsetHeight;
				if (i < index)
				{
					scrollOffset += listItemHeight;
					if (i > toTopIndex - 1)
					{
						scrollToCenter += listItems[i - toTopIndex].offsetHeight;
					}
				}
				contentHeight += listItemHeight;
			}
			scrollOffset = scrollToCenter;
			if (scrollOffset + listFolder.offsetHeight > contentHeight)
			{
				scrollOffset = contentHeight - listFolder.offsetHeight;
			}
			scrollTo(listFolder, scrollOffset, duration);
		}
	}

	function scrollTo(element, to, duration)
	{
		if (duration <= 0) return;
		var difference = to - element.scrollTop;
		var perTick = difference / duration * 10;

		setTimeout(function () {
			element.scrollTop = element.scrollTop + perTick;
			if (element.scrollTop === to) return;
			scrollTo(element, to, duration - 10);
		}, 10);
	}

	function indexOfArray(array, item)
	{
		var index = -1;
		for (var i = 0, l = array.length; i < l; i++)
		{
			if (array[i] === item)
			{
				index = i;
				break;
			}
		}
		return index;
	}

	// Function: deep copy
	function deepExtend(out) // arguments: (source, source1, source2, ...)
	{
		out = out || {};

		for (var i = 1; i < arguments.length; i++)
		{
			var obj = arguments[i];

			if (!obj)
				continue;

			for (var key in obj)
			{
				if (obj.hasOwnProperty(key))
				{
					if (typeof obj[key] === 'object'
						&& obj[key] !== null
						&& !Array.isArray(obj[key])
						&& !(obj[key] instanceof Date)
						&& !(obj[key] === 'function'))
					{
						out[key] = deepExtend(out[key], obj[key]);
					}
					else
					{
						if (Array.isArray(obj[key]))
						{
							out[key] = obj[key].slice();
						}
						else
						{
							out[key] = obj[key];
						}
					}
				}
			}
		}
		return out;
	}

	function getScript(url, callback)
	{
		var script = document.createElement('script'),
				prior = document.getElementsByTagName('script')[0];
		script.async = 1;
		prior.parentNode.insertBefore(script, prior);

		script.onload = script.onreadystatechange = script.onerror = function (_, isAbort) {
			if (_.type === 'error')
			{
				script.onload = script.onreadystatechange = script.onerror = null;
				script = undefined;
				callback && callback(false);
			}
			else if (isAbort || (script && !script.readyState) || (script && /loaded|complete/.test(script.readyState)))
			{
				script.onload = script.onreadystatechange = null;
				if (!document.documentMode || document.documentMode >= 10)
				{
					script = undefined;
				}
				!isAbort && callback && callback(true);
			}
		};

		script.src = url;
	}

	// Process class
	function hasClass(element, className)
	{
		if (element.classList)
			return element.classList.contains(className);
		else
			return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
	}

	function closet(element, className)
	{
		var closetElement = null;
		if (hasClass(element, className))
		{
			closetElement = element;
		}
		else
		{
			closetElement = findParent(element, '.' + className);
		}
		return closetElement;
	}

	function addClass(element, className)
	{
		if (!element) return;
		if (element.classList)
			element.classList.add(className);
		else
			element.className += ' ' + className;
	}

	function removeClass(element, className)
	{
		if (!element) return;
		if (element.classList)
			element.classList.remove(className);
		else
			element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	}

	function getElement(element)
	{
		var resultElement = null;
		if (element.jquery)
		{
			element =  element.length > 1 ? element.get() : element[0];
		}
		if (NodeList.prototype.isPrototypeOf(element) || Array.isArray(element))
		{
			resultElement = element[0];
		}
		else if (element.nodeType)
		{
			resultElement = element;
		}
		return resultElement;
	}

	function findParent(element, selector)
	{
		while ((element = element.parentElement) && !matches(element, selector)) {}
		return element;
	}

	function matches(el, selector)
	{
	  return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
	}

	function initTemplate(template, data, sourceData)
	{
		var result = template;
		for (var key in data)
		{
			if (data.hasOwnProperty(key))
			{
				var dataValue = data[key];
				if (typeof data[key] === 'function')
				{
					dataValue = data[key](sourceData);
				}
				result = result.replace(new RegExp('{{' + key + '}}', "g"), dataValue);
			}
		}
		return result;
	}

	function triggerEvent(element, eventName, eventData)
	{
		var event = null;
		if (window.CustomEvent)
		{
			if (typeof window.CustomEvent !== "function")
			{
				var innerCustomEvent = function (event, params) {
					params = params || {bubbles: false, cancelable: false, detail: undefined};
					var evt = document.createEvent('CustomEvent');
					evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
					return evt;
				};

				innerCustomEvent.prototype = window.Event.prototype;
				window.CustomEvent = innerCustomEvent;
			}
			event = new CustomEvent(eventName, {detail: eventData});
		}
		else
		{
			event = document.createEvent('CustomEvent');
			event.initCustomEvent(eventName, true, true, eventData);
		}

		element.dispatchEvent(event);
	}

	function bindClickIgnoreDrag(elements, callback, isBind)
	{
		var mouseDownX = 0, mouseDownY = 0;
		var eventListenerName = isBind !== false ? 'on' : 'off';

		[].forEach.call(elements, function (element) {
			extendOnOff(element)[eventListenerName]('mousedown', mouseDownHandler);
		});

		function mouseDownHandler(event)
		{
			mouseDownX = event.pageX;
			mouseDownY = event.pageY;
			event.target.addEventListener('mouseup', mouseUpMoveHandler);
			event.target.addEventListener('mousemove', mouseUpMoveHandler);
		}

		function mouseUpMoveHandler(event)
		{
			if (event.type === 'mouseup' && event.which <= 1) //only for left key
			{
				callback(event);
			}
			if (event.type === 'mousemove' && (Math.abs(event.pageX - mouseDownX) + Math.abs(event.pageY - mouseDownY) === 0))
			{
				return;
			}
			event.target.removeEventListener('mouseup', mouseUpMoveHandler);
			event.target.removeEventListener('mousemove', mouseUpMoveHandler);
		}
	}

	//Extend on/off methods
	function extendOnOff(el)
	{
		if (el.length === 0)
			return null;
		var events = {
			on: function (event, callback, opts) {
				if (!this.namespaces) // save the namespaces on the DOM element itself
					this.namespaces = {};

				this.namespaces[event] = callback;
				var options = opts || false;

				this.addEventListener(event.split('.')[0], callback, options);
				return this;
			},
			off: function (event) {
				this.removeEventListener(event.split('.')[0], this.namespaces[event]);
				delete this.namespaces[event];
				return this;
			}
		};

		// Extend the DOM with these above custom methods
		if (!el.isExtendOnOff)
		{
			el.on = Element.prototype.on = events.on;
			el.off = Element.prototype.off = events.off;
			el.isExtendOnOff = true;
		}
		return el;
	}

	window[EPGListFeedCallback] = function (epgData) {
		triggerEvent(document, EPGList.config.eventTypes.epgCallbackEvent, epgData);
	};

	window.EPGList = function () {
		return function (element, options){
			var epgList = new EPGList(element, options);
			this.goToDay = function (formatDate) {
				epgList.goToDay(formatDate);
			};
			this.goLive = function () {
				epgList.goLive();
			};
			this.playNext = function (isLive) {
				isLive = !!isLive;
				epgList.playProgramWithState(isLive, true);
			};
			this.getFormatDate = function (dateString) {
				return getFormatDate(dateString);
			};
			this.addDaysToDate = function (dateString, offset) {
				return addDaysToDate(dateString, offset);
			};
			this.getEPGListFeed = function (epgDate) {
				epgDate = epgDate || epgList.config.epgConfig.epgListDate;
				return epgList.getEPGData(epgDate);
			};
			this.getPlayingEPGItem = function () {
				var playingEpgData = epgList.config.player.playingEPGData,
						playingEpgIndex = epgList.config.player.playingEPGIndex;
				return (playingEpgData && playingEpgData) ? playingEpgData[playingEpgIndex] : null;
			};
			this.getLiveEPGItem = function () {
				if (typeof epgList.config.epgConfig.epgLiveIndex === 'number' && epgList.config.epgConfig.epgLiveIndex >= 0)
				{
					return epgList.getEPGData(epgList.config.epgConfig.epgLiveDate)[epgList.config.epgConfig.epgLiveIndex];
				}
				return null;
			};
			this.getNextEPGItem = function () {
				return epgList.getNextEPGItem();
			};
			this.scrollToPlayingItem = function () {
				return epgList.scrollToItem(true);
			};
			this.on = function (eventName, callback) {
				epgList.event.on(eventName, callback);
				return this;
			};
			this.off = function (eventName, callback) {
				epgList.event.off(eventName, callback);
				return this;
			};
		};
	}();
	window.EPGList.EVENTS = EVENT_NAMES;
})(window);

class Event
{
	constructor()
	{
		this._cache = {};
	}
	on(eventName, callback)
	{
		if (!this._cache[eventName])
		{
			this._cache[eventName] = [];
		}

		if (typeof callback === 'function' && this._cache[eventName].indexOf(callback) === -1)
		{
			this._cache[eventName].push(callback);
		}
		else
		{
			typeof callback !== 'function' && alert(`Your added callback ${callback} is not one valid function.`);
			this._cache[eventName].indexOf(callback) !== -1 && alert(`Same on(eventName, callback) have been called!`);
		}
		return this;
	}
	off(eventName, callback)
	{
		let eventCallbacks = this._cache[eventName];
		if (Array.isArray(eventCallbacks) && eventCallbacks.length)
		{
			if (callback)
			{
				eventCallbacks.splice(eventCallbacks.indexOf(callback), 1);
			}
			else
			{
				eventCallbacks.length = 0;
			}
		}
		return this;
	}
	trigger(eventName, data)
	{
		let eventCallbacks = this._cache[eventName];
		if (eventCallbacks && eventCallbacks.length)
		{
			eventCallbacks.forEach((callback) => {
				callback(data);
			});
		}
		return this;
	}
}